<template>
    <div class="recovery-pass">

        <div class="contenedor-formulario">

            <div class="contenedor-logo">
                <img src="/img/logo-uc3m-alumni-dark.png" alt="Logo u3cm">
            </div>

            <a @click="$emit('close')" class="close">✕</a>


            <h2>Restablecer mi contraseña</h2>

            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form @submit.prevent="validate" :valid="valid" class="formulario">
                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            placeholder="DNI">
                            <label for="">DNI, NIE o Pasaporte <popup-info :data="'ayuda-login'">
                            </popup-info></label>
                            <input type="text" :class="classes" v-model="data.username" name="dni" placeholder="Dni">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Email</label>
                            <input type="text" :class="classes" v-model="data.email" name="email" placeholder="Email">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-boton">
                        <button class="btn -skull-blue" :loading="loading">Enviar correo electrónico</button>
                    </div>
                </form>
            </ValidationObserver>

        </div>

    </div>
</template>

<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'recovery-pass',
        mounted(){
            this.data.type = this.type;
        },
        props : ['type'],
        data: () => ({
            data: {
                email: '',
                username: '',
                type : ''
            },
            loading : false
        }),
        methods: {
            ...mapActions([
                'requestRecoveryPass'
            ]),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.requestRecoveryPass(this.data).then(() => {
                            this.loading = false;
                        })
                    }
                });
            }
        }
    }
</script>