<template>
    <ValidationObserver ref="form">
        <form class="row-between" @submit.prevent="validate">
            <div class="contenedor-input">
                <label for="">Tipo de contacto*</label>
                <ValidationProvider name="type" rules="required" v-slot="{
                    errors, ariaInput, ariaMsg,
                    classes
                }">
                    <select :class="classes" name="type" id="" v-model="data.type">
                        <option value="">Selecciona tipo de contacto</option>
                        <option value="2">Ayuda/Incidencia para Programa</option>
                        <option value="3" v-if="mentorias && mentorias.length != 0">Ayuda/Incidencia para Mentoría
                        </option>
                        <option value="4">Otro</option>
                    </select>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input" v-if="data.type == 2">
                <label for="">Programas*</label>
                <ValidationProvider name="type" rules="required" v-slot="{
                    errors, ariaInput, ariaMsg,
                    classes
                }">
                    <select :class="classes" name="type" id="" v-model="data.program">
                        <option value="">Selecciona el programa</option>
                        <option :value="programa.id" :key="key" v-for="(programa, key) in programas">{{ programa.name }}
                        </option>
                    </select>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input" v-if="data.type == 3">
                <label for="">Mentorías*</label>
                <ValidationProvider name="type" rules="required" v-slot="{
                    errors, ariaInput, ariaMsg,
                    classes
                }">
                    <select :class="classes" name="type" id="" v-model="data.mentoring">
                        <option value="">Selecciona la mentoría</option>
                        <option :value="mentoria.id" :key="key" v-for="(mentoria, key) in mentorias">
                            {{ mentoria.program.name }} <template v-if="usertype == 'mentee'">-
                                {{ mentoria.profesor.name }}</template><template v-if="usertype == 'mentor'">-
                                {{ mentoria.mentee.name }}</template></option>
                    </select>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <div class="contenedor-input" v-if="data.type == 4">
                <ValidationProvider name="asunto" rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                    <label for="">Motivo del contacto*</label>
                    <input :class="classes" type="text" name="asunto" v-model="data.subject">
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>


            <div class="contenedor-input">
                <label for="">Contenido del mensaje*</label>
                <ValidationProvider name="tematica" rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                    <textarea :class="classes" name="" id="" cols="30" rows="10" v-model="data.message"></textarea>
                    <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>





            <button :loading="loading" class="btn -skull">Enviar</button>

        </form>
    </ValidationObserver>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'formulario-contacto',
    mounted() {
        if (this.$route.params.type == 'mentoria') {
            this.data.type = "3";
            if (this.$route.params.id) {
                this.data.mentoring = this.$route.params.id;
            }
        }
        if (this.$route.params.type == 'programa') {
            this.data.type = "2";
            if (this.$route.params.id) {
                this.data.program = this.$route.params.id;
            }
        }
    },
    data() {
        return {
            data: {
                email: '',
                phone: '',
                subject: '',
                message: '',
                type: '',
                mentee_id: '',
                profesor_id: '',
                program: '',
                mentoring: '',
                source: '',
            },
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            mentorias: 'getMentorias',
            programas: 'getOwnProgramas',
            usertype: 'getUserType'
        })
    },
    methods: {
        ...mapActions(['sendContacto']),
        validate() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.loading = true;

                    if (this.usertype == 'mentee') {
                        this.data.source = 2;
                    } else {
                        this.data.source = 1;
                    }
                    this.sendContacto(this.data).then(r => {
                        if (r) {
                            this.reset();
                        }
                    })
                }
            });
        },
        reset() {
            this.loading = false;
            this.data = {
                email: '',
                phone: '',
                subject: '',
                message: '',
                type: '',
                mentee_id: '',
                profesor_id: '',
                program: '',
                mentoring: '',
            }
            setTimeout(() => {
                this.$refs.form.reset();
            }, 200)
        }
    }
}
</script>