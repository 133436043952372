<template>
    <section class="perfil-module" :show="show">

        <h2>Información profesional</h2>
        
        <template v-if="show">
            <campo-profesional></campo-profesional>
            <legend v-if="data.length != 0">Experiencia laboral</legend>
            <profesional-el :key="key" :id="key" :values="n" v-for="(n,key) in data" @reload="setData"></profesional-el>
            <a v-if="!add" @click="add = true" class="add-btn"><strong>+</strong> Añadir experiencia</a>
            <profesional-el :id="'new'" v-if="add" :new="true" @reload="setData" @cancel="add = false"></profesional-el>
        </template>

        <button class="show-btn" @click="show = !show">{{(show) ? '-' : '+'}}</button>

    </section>
</template>

<script>

    import { mapActions } from 'vuex';

    import profesional from '@/components/mi-perfil/profesional-el';

    import campoprofesional from '@/components/mi-perfil/campo-profesional'

    export default {
        name: 'informacion-profesional',
        mounted() {
            this.setData();
        },
        data: () => ({
            show: true,
            data: '',
            add: false
        }),
        methods: {
            ...mapActions([
                'requestProfesional'
            ]),
            setData() {

                this.add = false;

                this.requestProfesional().then(r => {
                    this.data = r;
                })
            }
        },
        components: {
            'profesional-el': profesional,
            'campo-profesional' : campoprofesional
        },
    }

</script>