<template>
    <article class="mentoria" :open="!hide">
        <h2 v-if="usertype == 'mentee'"><router-link :to="$t('links.fichamentor')+'/'+data.profesor.id" class="link-arrow">{{data.profesor.name}}</router-link></h2>
        <h2 v-if="usertype == 'mentor'"><router-link :to="$t('links.fichamentee')+'/'+data.mentee.id" class="link-arrow">{{data.mentee.name}}</router-link></h2>
        <p class="programa">{{data.program.name}}</p>
        <h3 class="status" v-if="usertype == 'mentee'" :status="'status-'+data.status">{{domains.status_mentee[data.status]}}</h3>
        <h3 class="status" v-if="usertype == 'mentor'" :status="'status-'+data.status">{{domains.status_mentor[data.status]}}</h3>
        <p class="fecha" v-if="data.start_date">Desde: {{data.start_date | parsedate}} <template v-if="data.end_date">- Hasta: {{data.end_date | parsedate}}</template></p>

        <div class="confirmar-container" v-if="data.status == 0 && usertype == 'mentee'">
            <p>Si el mentor/a ya ha contactado contigo, por favor confirma a continuación:</p>
            <a :loading="loading" @click="confirmarContacto" class="btn -skull-orange -small">Confirmar contacto del
                mentor/a</a>
        </div>

        <div class="confirmar-container" v-if="data.status == 0 && usertype == 'mentor'">
            <p>No olvides contactar con tu mentee</p>
        </div>

        <div class="hide" v-if="!hide & data.status != 0">
            <div class="valoracion-container row-start" v-if="usertype == 'mentee'">
                <p v-if="data.status != 3"><strong>Valoración mentoría:</strong> {{(data.mentee_review) ? 'Realizada' : 'Pendiente'}}</p>
                <p v-else><strong>Valoración mentoría:</strong> No disponible</p>
                <router-link v-if="!!!data.mentee_review && data.status != 3" :to="$t('links.valorarmentoria')+'/'+data.id" class="link-arrow">Valorar mentoría</router-link>
            </div>

            <div class="valoracion-container row-start" v-if="usertype == 'mentor'">
                <p v-if="data.status != 3"><strong>Valoración mentoría:</strong> {{(data.profesor_review) ? 'Realizada' : 'Pendiente'}}</p>
                <p v-else><strong>Valoración mentoría:</strong> No disponible</p>
                <router-link v-if="!!!data.profesor_review && data.status != 3" :to="$t('links.valorarmentoria')+'/'+data.id" class="link-arrow">Valorar mentoría</router-link>
            </div>
            
            <p v-if="data.title_url"><strong>Certificado:</strong> <a class="download-link" target="_blank" download="true" :href="data.title_url">Descargar</a></p>
            <p v-else>Certificado no disponible</p>
            <a @click="showcancelacion = true" v-if="data.status == 1" class="btn -skull-orange">Solicitar cancelación</a>
            <div class="help">
                <router-link class="link-arrow" :to="$t('links.contacto')+'/mentoria/'+data.id">Solicitar ayuda / Reportar incidencia</router-link>
            </div>
        </div>

        <div class="help" v-if="data.status == 0">
            <router-link class="link-arrow" :to="$t('links.contacto')+'/mentoria/'+data.id">Solicitar ayuda / Reportar incidencia</router-link>
        </div>


        <button v-if="data.status != 0" @click="hide = !hide" class="show-btn">{{(!hide) ? '-' :
            '+'}}</button>

        <div class="cover-cancelacion" v-if="showcancelacion">
            <div class="popup-cancelacion formulario">
                <h2>Finalización anticipada</h2>
                <p>Por favor explicanos el motivo por el cual deseas finalizar anticipadamente la mentoría.</p>
                <ValidationObserver ref="form">

                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <div class="contenedor-input">
                            <label for="cancel_reason">Motivo de la finalización*</label>
                            <select :class="classes" name="cancel_reason" v-model="cancel.cancel_reason"
                                id="cancel_reason">
                                <option value="">Selecciona el motivo</option>
                                <option :value="key" :key="key" v-for="(reason,key) in domains.cancel_reasons">
                                    {{reason}}
                                </option>
                            </select>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    <div class="contenedor-input">
                        <label for="">Escribe una breve explicación</label>
                        <textarea v-model="cancel.cancel_observation" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                </ValidationObserver>

                <div class="contenedor-boton row-center">
                    <a @click="cancelacion" class="btn -dark-blue" :loading="loading">Solicitar finalización</a>
                    <a @click="closepopup" class="btn -skull-blue">Cancelar</a>
                </div>
            </div>
        </div>

    </article>
</template>


<script>

    import { mapActions, mapGetters } from 'vuex';

    import moment from 'moment';

    export default {
        name: 'mentoria',
        props: ['data'],
        data: () => ({
            hide: true,
            loading: false,
            showcancelacion: false,
            cancel: {
                cancel_reason: '',
                cancel_observation: ''
            }
        }),
        methods: {
            ...mapActions(['confirmarMentoria', 'cancelarMentoria','getPrivateDoc']),
            confirmarContacto() {
                this.loading = true;
                this.confirmarMentoria(this.data.id).then(() => {
                    this.loading = false;
                });
            },
            cancelacion() {

                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.cancel.id = this.data.id
                        this.cancel.status = 3

                        this.loading = true;
                        this.cancelarMentoria(this.cancel).then(r => {
                            this.loading = false;
                            if(r){
                                this.closepopup();
                            }
                        })
                    }
                });

            },
            closepopup() {
                this.cancel = {
                    cancel_reason: '',
                    cancel_observation: ''
                };
                this.showcancelacion = false;
            }
        },
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                usertype : 'getUserType'
            })
        },
       
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }
</script>