<template lang="html">

    <div class="dropzone row-center" :added="!!actualDoc.ficheroB64ParaAltas || !!fakeupload">

        <div class="text" v-if="!!!actualDoc.ficheroB64ParaAltas & !fakeupload">
            <span class="upload-icon-wrapper">
                <img src="/img/upload.svg" v-if="!errorformat" class="tag-icon">
                <figure v-if="errorformat">✕</figure>
            </span>
            <p>{{textoUpload}}</p>
        </div>

        <div class="text" v-if="!!actualDoc.ficheroB64ParaAltas || !!fakeupload">
            <img src="/img/success.svg" class="tag-icon">
            <p>Fichero <template v-if="!!actualDoc.nombre">{{actualDoc.nombre}}</template> añadido correctamente</p>
        </div>

        <ValidationObserver ref="form" class="row-between">
            <ValidationProvider rules="size:2000|mimes:${formatos.formats}`" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                <input type="file" :class="classes" id="file" name="file" ref="file" @change="handleFileUpload($event)" />
                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
            </ValidationProvider>
        </ValidationObserver>

    </div>

</template>

<script lang="js">





    import formatosPermitidos from '@/validator/docsformats.js';

    import { mapActions } from 'vuex';

    export default {
        name: 'dropzone',
        props: ['fakeupload', 'onlyimg'],
        mounted() {
            this.formatos = formatosPermitidos;
            if (this.onlyimg) {
                this.formatos.formats = formatosPermitidos.images;
            }

        },
        data() {
            return {
                formatos: '',
                actualDoc: {
                    ficheroB64ParaAltas: '',
                    extension_adjunto: '',
                    fecha: '',
                    nombre: ''
                },
                errorformat: false
            }
        },
        computed: {
            textoUpload() {
                let mobile = ['iPhone', 'iPad', 'Android'];
                let isMobile = false;
                let r;
                mobile.forEach(e => { if (navigator.userAgent.includes(e)) { isMobile = true; } });
                if (!this.onlyimg) {
                    r = (isMobile) ? 'Haz click para adjuntar el archivo' : 'Arrastra o selecciona el archivo para subir el documento'
                } else {
                    r = (isMobile) ? 'Haz click para adjuntar la imagen' : 'Arrastra o selecciona el archivo para subir la imagen'
                }
                return r;
            },
        },
        methods: {
            ...mapActions([]),
            handleFileUpload(event) {

                this.$refs.form.validate()
                    .then(itsOk => {

                        if (itsOk) {

                            let file = event.target.files[0];

                            if (file) {

                                let type = (file.type) ? file.type.split("/")[1] : file.name.split(".").pop();

                                let fechaactual = new Date();

                                this.actualDoc.fecha = fechaactual;

                                this.actualDoc.nombre = file.name;


                                if (this.formatos.formats.some(f => f.includes(type))) {

                                    if (this.formatos.relations[type]) {
                                        this.actualDoc.extension_adjunto = this.formatos.relations[type]
                                    } else {
                                        this.actualDoc.extension_adjunto = type;
                                    }


                                    this.showerror = false;

                                    this.getBase64(file).then(data => {
                                        this.actualDoc.ficheroB64ParaAltas = data;
                                        this.emit();
                                    });


                                    this.errorformat = false;

                                } else {
                                    this.errorformat = true;
                                    this.actualDoc = {
                                        ficheroB64ParaAltas: '',
                                        extension_adjunto: '',
                                        fecha: ''
                                    };
                                }
                            }

                        }

                    });
            },
            getBase64: function (file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            emit() {
                this.$emit('emit', this.actualDoc);
            },
            eliminar() {
                this.reset();
                this.emit();
                this.$emit('eraseDoc');
            },
            reset() {
                this.errorformat = false;
                this.actualDoc = {
                    ficheroB64ParaAltas: '',
                    extension_adjunto: '',
                    fecha: ''
                };
                (document.getElementById("file")) ? document.getElementById("file").value = '' : null;
            }
        }

    }
</script>

<style scoped lang="scss">
    /*DROPZONE*/

    .dropzone {
        width: 100%;
        min-height: 200px;
        max-width: 500px;
        background: #f2f2f2;
        padding: 20px;
        position: relative;
        border: 2px solid transparent;
        text-align: center;

        &.full {
            max-width: 100%;
        }


        &[error="true"] {
            background: #ffd7d7;
            border: 2px solid red;
        }

        &[added="true"] {
            background: #f8fde9;
            border: 2px solid rgb(67, 134, 4);
        }

        svg {
            width: 40px;
            height: 40px;
            filter: grayscale(1);
            opacity: 0.5;
        }

        figure {
            font-size: 50px;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 3;
            height: 100%;
        }

        p {
            text-align: center;
            margin-top: 10px;
        }

        .link {
            width: 100%;
            position: absolute;
            bottom: -25px;
            left: 0;
            text-align: center;
            z-index: 4;
            transition: .5s;
            opacity: 0;
            pointer-events: none;
            background: none;
            padding-right: 0;

            &[show=true] {
                transform: translateY(-40px);
                opacity: 1;
                pointer-events: visible;
            }
        }

        .error {
            position: absolute;
            width: 100%;
            bottom: 10px;
            left: 0;
            color: red;
            font-size: 12px;
            text-align: center;
        }

        .popup-info {
            top: 10px;
            right: 10px;
            z-index: 5;
            position: absolute;
        }

        .tag-icon {
            max-width: 50px;
        }

    }



    /*FIN DROPZONE*/
</style>