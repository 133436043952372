<template>
    <div class="cambio-password">
        <a class="link-arrow" @click="showcambiopass = true">Cambiar contraseña</a>

        <div class="cover-popup row-center" v-if="showcambiopass">
            <div class="popup formulario">
                <h2>Cambiar contraseña</h2>
                <ValidationObserver ref="form">
                    <div class="contenedor-input">
                        <ValidationProvider :rules="{required:true,regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            vid="password">
                            <label for="email">Contraseña*<popup-info :data="'contrasena'">
                            </popup-info></label>
                            <input type="password" :class="classes" v-model="data.password_new" name="password">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input">
                        <ValidationProvider :rules="{required:true,confirmed:'password',regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}"
                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="email">Repetir contraseña*</label>
                            <input type="password" :class="classes" v-model="data.passowrd_new_confirmation"
                                name="password">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-boton row-center">
                        <a @click="send" class="btn -dark-blue" :loading="loading">Cambiar contraseña</a>
                        <a @click="cancel" class="btn -skull-blue">Cancelar</a>
                    </div>

                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'

    export default {
        name: 'cambio-password',
        data: () => ({
            loading: false,
            showcambiopass: false,
            data: {
                password_new: '',
                passowrd_new_confirmation: ''
            }
        }),
        methods: {
            ...mapActions(['requestChangeUser']),
            send() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.requestChangeUser(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.cancel();
                            }
                        })
                    }
                });
            },
            cancel() {
                this.data = {
                    password_confirmated: '',
                    password_new_confirmation: ''
                }
                this.showcambiopass = false;
            }
        }
    }
</script>