<template>
    <section class="perfil-module" :show="show">

        <h2>Información académica</h2>

        <template v-if="show">
            <template v-if="data.length != 0">
                <academico-el :key="key" :id="key" :values="n" v-for="(n,key) in data" @reload="setData"></academico-el>
            </template>
            
            <a v-if="!add" @click="add = true" class="add-btn"><strong>+</strong> Añadir titulación</a>
            <academico-el v-if="add" :id="'new'" :new="true" @reload="setData"></academico-el>
        </template>

        <button class="show-btn" @click="show = !show">{{(show) ? '-' : '+'}}</button>

    </section>
</template>


<script>

    import academico from '@/components/mi-perfil/academico-el';

    import { mapActions } from 'vuex';

    export default {
        name: 'informacion-academica',
        mounted() {
            this.setData();
        },
        data: () => ({
            show: true,
            data: '',
            add: false
        }),
        methods: {
            ...mapActions([
                'requestAcademics'
            ]),
            setData() {
                this.requestAcademics().then(r => {
                    this.data = r;
                });
                this.add = false;
            }
        },
        components: {
            'academico-el': academico
        },
    }
</script>