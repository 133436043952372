<template>
    <article class="programa-el row-start">

        <div class="imagen">
            <img v-if="data.image" :src="data.image" :alt="data.name">
        </div>
        <div class="texto">
            <h3 class="titulo">{{data.name}}</h3>
            <p v-html="data.subtitle"></p>
            <p v-if="data.slug != 'mentoring-alumni-uc3m'"><strong>Duración:</strong> {{data.start_date | parsedate}} a {{data.end_date | parsedate}}</p>
            <p><strong>Inscripción:</strong>
                <span :open="data.can_sub_public" v-if="data.can_sub_public"> Abierta <template v-if="data.inscription_open_until">hasta</template> {{data.inscription_open_until}}</span>
                <span :open="data.can_sub_public" v-else> Cerrada</span>
            </p>
            <p><strong>Requisitos del mentor/a:</strong> {{data.profesor_participate}}</p>
            <p><strong>Requisitos del mentee:</strong> {{data.mentee_participate}}</p>
            <a href="" class="link-arrow">Ver detalle</a>
            <a v-if="data.can_sub" @click="requestInscripcion" :loading="loading" class="btn -skull-orange">Inscribirme
                a este programa</a>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 0">Este programa ha finalizado</p>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 1">Este programa ya no acepta más
                participantes</p>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 2"></p>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 3">Ya estás inscrito/a a este programa</p>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 4">Inscripción no disponible, ya estás
                inscrito/a a otro programa
            </p>
            <p class="reason" v-if="!data.can_sub && data.close_reason == 6">Inscripción no disponible, ya estás
                inscrito/a a otro programa</p>


            <router-link class="enlace-cover" :to="$t('links.programas')+'/'+data.slug"></router-link>


        </div>

    </article>
</template>


<script>

    import moment from 'moment';

    import { mapActions } from 'vuex';

    export default {
        name: 'programa',
        props: ['data'],
        data: () => ({
            loading: false
        }),
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        },
        methods: {
            ...mapActions(['inscripcionPrograma']),
            requestInscripcion() {
                this.loading = true;

                this.inscripcionPrograma(this.data.id).then(r => {
                    if (r) {
                        this.data.can_sub = false;
                        this.data.close_reason = 3;
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>