<template>

    <article class="academica-el" :edit="edit">

        <template v-if="!edit && values">

            <a @click="setEdit" v-if="!(values.external == 1)">
                <icon-editar :edit="edit"></icon-editar>
            </a>
            <h3>Titulación: {{values.name}}</h3>
            <p v-if="values.uc3m == 1">Titulación UC3M</p>
            <p>

                <template v-if="values.end_date">
                    Año de finalización: {{values.end_date}}
                </template>
                <template v-else>
                    Actualidad
                </template>
            </p>

        </template>

        <template v-if="edit">

            <icon-editar v-if="!edit" :edit="edit"></icon-editar>
            <icon-delete @click="erase" :loading="loadingerase" v-if="data.dataId" :edit="edit"></icon-delete>


            <ValidationObserver ref="form" class="row-between ais">
                <div class="toggle">
                    <input type="checkbox" :id="'uc3m-'+id" v-model="data.uc3m">
                    <label :for="'uc3m-'+id">Es titulación UC3M</label>
                </div>


                <div class="contenedor-input -middle">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Nombre de la titulación*</label>
                        <input type="text" :class="classes" v-model="data.name">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>


                <div class="contenedor-input -middle">
                    <label for="" >Año de finalización</label>
               
                    <ValidationProvider rules="numeric" v-slot="{ errors,ariaInput,ariaMsg,classes }" v-if="!data.actual">
                        <input v-model="data.end_date" v-if="!data.actual" type="text" :class="classes">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <div class="toggle mb0 mt">
                        <input type="checkbox" :id="'academica-'+id" v-model="data.actual">
                        <label :for="'academica-'+id">Actualmente</label>
                    </div>
                    
           

                </div>




            </ValidationObserver>

            <div class="contenedor-botones">
                <div>
                    <button class="btn" @click="validate" :loading="loading">Guardar</button>
                    <button class="btn -skull-blue" @click="cancel">Cancelar</button>
                </div>
            </div>

        </template>



    </article>

</template>


<script>


    import { mapGetters, mapActions } from 'vuex';

    import moment from 'moment';

    export default {
        name: 'academico',
        mounted() {
            if (this.new) {
                this.edit = true;
            }
        },
        data: () => ({
            edit: false,
            loading: false,
            loadingerase: false,
            data: {
                end_date: '',
                name: '',
                level: '',
                year: '',
                actual: false
            }
        }),
        props: ['new', 'values', 'id'],
        computed: {
            ...mapGetters({
                domains: 'getDomains'
            })
        },
        methods: {
            ...mapActions([
                'saveAcademic',
                'removeAcademic'
            ]),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        (this.data.actual) ? this.data.end_date = null : null;
                        this.saveAcademic(this.data).then(r => {
                            if (r) {
                                this.edit = false;
                                this.loading = false;
                                this.$emit("reload")
                            }
                        });
                    }
                });
            },
            cancel() {
                this.edit = false;
            },
            setEdit() {
                this.edit = true;
                for (let i in this.values) {
                    this.data[i] = this.values[i];
                }
                this.data.actual = (!this.values.end_date);
            },
            erase() {

                this.loadingerase = true;
                this.removeAcademic(this.data.dataId).then(r => {
                    if (r) {
                        this.loadingerase = false;
                        this.$emit("reload");
                        this.edit = false;
                    }
                })
            }
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }


</script>