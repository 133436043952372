<template>

    <section class="perfil-module row-between -personal" :show="show">

        <h2>Información personal</h2>

        <template v-if="show">

            <template v-if="!edit">

                <div class="dato -middle">
                    <p><strong>DNI/NIE o pasaporte:</strong> {{values.dni}} <popup-info :data="'cambiar-dni'">
                        </popup-info>
                    </p>
                </div>

                <div class="dato -middle">
                    <p><strong>Email personal:</strong> <a :href="'mailto:'+values.email">{{values.email}}</a></p>
                </div>

                <div class="dato -middle">
                    <p><strong>País de residencia:</strong> {{values.country}}</p>
                </div>

                <div class="dato -middle">
                    <p><strong>Provincia/Estado de residencia:</strong> {{values.province}}</p>
                </div>


                <div class="dato -middle">
                    <p><strong>Email UC3M: </strong>
                        <a v-if="values.email_internal"
                            :href="'mailto:'+values.email_internal">{{values.email_internal}} </a>
                        <template v-else>
                            Sin definir
                        </template>
                        <popup-info v-if="data.saml && usertype == 'mentee'" :data="'cambiar-email-uc3m'"></popup-info>
                    </p>
                </div>

                <div class="dato -middle">
                    <p>
                        <strong>Teléfono:</strong> <a v-if="values.phone"
                            :href="'tel:'+values.phone">{{values.phone}}</a>
                        <template v-else>
                            Sin definir
                        </template>
                    </p>
                </div>

                <div class="dato -middle">
                    <p><strong>Género:</strong> {{domains.gender[values.gender]}} <popup-info :data="'cambiar-genero'">
                        </popup-info>
                    </p>
                </div>




                <div class="dato -middle">
                    <p><strong>Año de nacimiento:</strong>
                        <template v-if="values.birthday_date">
                            {{values.birthday_date | onlyyear}}
                        </template>
                        <template v-else>
                            Sin definir
                        </template>
                    </p>
                </div>


                <div class="dato -middle">
                    <p><strong>Egresado/a UC3M:</strong> {{(values.old_alumn == 1) ? 'Sí' : 'No' }} <popup-info
                            :data="'cambiar-alumno'"></popup-info>
                    </p>
                </div>

            </template>

            <template v-if="edit">

                <ValidationObserver ref="form" class="row-between ais">



                    <div class="contenedor-input -middle">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Email personal*</label>
                            <input type="text" :class="classes" v-model="data.email">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -middle" v-if="!data.saml && usertype == 'mentor'">
                        <label for="">Email UC3M</label>
                        <input type="text" v-model="data.email_internal">
                    </div>

                    <div class="contenedor-input -middle">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="pais">País de residencia*</label>
                        
                            <select :class="classes" v-model="data.country" name="pais" id="pais">
                                <option value="">País de residencia</option>
                                <option :value="pais" :key="key" v-for="(pais,key) in domains.countries">{{pais}}</option>
                            </select>

                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input -middle" v-show="data.country != 'España'">
                        <ValidationProvider :rules="(data.country != 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="provincia">Provincia de residencia*</label>
                            <input type="text" :class="classes"
                                v-model="data.province" name="provincia" id="provincia">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -middle" v-show="data.country == 'España'">
                        <ValidationProvider :rules="(data.country == 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="provinciaselect">Provincia de residencia*</label>
                            <select  :class="classes" v-model="data.province" name="provinciaselect" id="provinciaselect">
                                <option value="">Provincia de residencia</option>
                                <option :value="provincia" :key="key" v-for="(provincia,key) in domains.provinces">
                                    {{provincia}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input -middle">
                        <ValidationProvider rules="numeric" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Teléfono</label>
                            <input type="text" v-model="data.phone" :class="classes">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <!-- <div class="contenedor-input -middle" v-if="data.saml == 0">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Año de nacimiento*</label>
                            <year-datepicker v-model="data.birthday_date" :name="'start_date'" :class="classes">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div> -->

                    <!-- 
                    <div class="contenedor-radios -dark" v-if="!data.saml">

                        <h4>Genero:</h4>

                        <div class="radio">
                            <input type="radio" name="gender" v-model="data.gender" id="gender-hombre" :value="0">
                            <label for="gender-hombre">Masculino</label>
                        </div>

                        <div class="radio">
                            <input type="radio" name="gender" v-model="data.gender" id="gender-mujer" :value="1">
                            <label for="gender-mujer">Femenino</label>
                        </div>

                    </div> -->

                </ValidationObserver>

                <div class="contenedor-botones">
                    <div>
                        <button class="btn" @click="validate" :loading="loading">Guardar</button>
                        <button class="btn -skull-blue" @click="cancel">Cancelar</button>
                    </div>
                </div>

            </template>

        </template>

        <icon-editar v-if="!edit" @click="setEdit" :edit="edit"></icon-editar>

        <button class="show-btn" @click="show = !show">{{(show) ? '-' : '+'}}</button>


    </section>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'informacion-personal',
        props: ['values'],
        mounted() {
            this.data = this.values;
            // this.data.birthday_date = new Date(this.data.birthday_date, 1, 1);
        },
        data: () => ({
            data: '',
            edit: false,
            loading: false,
            show: true
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                usertype: 'getUserType',
            })
        },
        methods: {
            ...mapActions(['requestChangeUser']),
            setEdit() {
                this.edit = true;
                this.data = JSON.parse(JSON.stringify(this.values));
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        let data = JSON.parse(JSON.stringify(this.data));

    


                        this.requestChangeUser(data).then(r => {
                            if (r) {
                                this.loading = false;
                                this.edit = false;
                            }
                        });
                    }
                });
            },
            cancel() {
                this.edit = false;
                this.data = JSON.parse(JSON.stringify(this.values));
            }
        },
        filters: {
            onlyyear(value) {
                if (typeof value == 'object') {
                    return moment(value).format("YYYY")
                } else {
                    return value;
                }
            }
        }
    }
</script>