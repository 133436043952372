<template>
    <div class="wrapper">
        <section class="encabezado -recursos-eventos">
            <h1>Recursos y eventos</h1>
        </section>

        <section class="filtro">

            <div class="container row-start">

                <div class="contenedor-input" v-if="date != 'intervalo'">
                    <select v-model="date">
                        <option value="">Fecha</option>
                        <option value="30">Último mes</option>
                        <option value="365">Último año</option>
                        <option value="intervalo">Intervalo personalizado</option>
                    </select>
                </div>

                <div class="contenedor-input" v-if="date == 'intervalo'">
                    <datepicker-multi v-model="interval_date"></datepicker-multi>
                </div>

                <div class="contenedor-multiselect">
                    <multiselect :multiple="true" :track-by="'1'" :label="'1'" v-model="filtro.type.search"
                        :options="Object.entries(domains.types)" :searchable="true" :close-on-select="true"
                        :show-labels="false" placeholder="Tipo de recurso o evento">
                    </multiselect>
                </div>

                <div class="contenedor-multiselect">

                    <multiselect :multiple="true" :track-by="'1'" :label="'1'" v-model="filtro.category.search"
                        :options="Object.entries(domains.categories)" :searchable="true" :close-on-select="true"
                        :show-labels="false" placeholder="Área profesional">
                    </multiselect>

                </div>

                <div class="contenedor-multiselect" v-if="programas && programas.length != 0">

                    <multiselect :multiple="true" :track-by="'name'" :label="'name'" v-model="filtro.programas.search"
                        :options="programas" :searchable="true" :close-on-select="true" :show-labels="false"
                        placeholder="Programas">
                    </multiselect>

                </div>


                <div class="contenedor-checks row-start">
                    <div class="check">
                        <input name="section" type="checkbox" v-model="filtro.section.search" :value="0" id="recursos">
                        <label for="recursos">Recursos</label>
                    </div>
                    <div class="check">
                        <input name="section" type="checkbox" v-model="filtro.section.search" :value="1" id="eventos">
                        <label for="eventos">Eventos</label>
                    </div>
                </div>


                <a @click="reset" class="btn -skull-blue">Limpiar filtro</a>
                <a @click="requestSearch" class="btn -dark-blue">Buscar</a>



            </div>

        </section>

        <section class="contenedor-recurso container" v-if="!loading">

            <template v-if="data.length  != 0">
                <article class="recurso-el" :key="key" v-for="(n,key) in data" :destacado="n.priority == 1">
                    <h4 v-if="n.priority == 1">Destacado</h4>
                    <h2>{{(n.section == 1) ? 'Evento' : 'Recurso'}}. {{n.title}}</h2>
                    <p>{{n.header}}</p>
                    <p v-if="n.section == 0">{{n.created_at | parsedate}}</p>
                    <router-link :to="$t('links.recursos')+'/'+n.slug" class="enlace-cover"></router-link>
                    <a href="" class="link-arrow">Ver detalle</a>
                </article>
            </template>
            <p v-else>No hay resultados para tu búsqueda</p>
        </section>

        <section class="loading -small" v-else></section>


    </div>
</template>


<script>


    import { mapGetters, mapActions } from 'vuex';

    import moment from 'moment';

    export default {
        name: 'recursos-y-eventos',
        mounted() {

            if (window.sessionStorage.getItem('filter') != null) {
                this.filtro = JSON.parse(window.sessionStorage.getItem('filter'));
            }

            this.requestSearch();


        },
        data: () => ({
            filtro: {
                type: {
                    name: 'type',
                    search: [],
                    json: true
                },
                category: {
                    name: 'category',
                    search: [],
                    json: true
                },
                section: {
                    name: 'section',
                    search: [],
                    json: true
                },
                date: {
                    name: 'created_at',
                    search: {
                        min: null,
                        max: null
                    }
                },
                programas: {
                    name: "programs.name",
                    search: [],
                    objectFilter: true
                }

            },
            date: '',
            interval_date: '',
            data: '',
            loading: false
        }),
        methods: {
            ...mapActions(['requestEntriesFiltered']),
            reset() {
                this.filtro = {
                    type: {
                        name: 'type',
                        search: [],
                        json: true
                    },
                    category: {
                        name: 'category',
                        search: [],
                        json: true
                    },
                    section: {
                        name: 'section',
                        search: [],
                        json: true
                    },
                    date: {
                        name: 'created_at',
                        search: {
                            min: null,
                            max: null
                        }
                    },
                    programas: {
                        name: "programs.name",
                        search: [],
                        objectFilter: true
                    }
                }

                window.sessionStorage.setItem('filter', JSON.stringify(this.filtro));

                this.date = '';
                this.interval_date = '';
            },
            requestSearch() {
                this.loading = true;

                //CALCULAR FECHA 
                if (this.date) {
                    if (this.date != 'intervalo') {
                        let date = moment().subtract(Number(this.date), 'days');
                        this.filtro.date.search.min = date.format("YYYY-MM-DD 00:00:00")
                        this.filtro.date.search.max = moment().format("YYYY-MM-DD 23:59:59");
                    } else {
                        this.filtro.date.search.min = moment(this.interval_date.start).format("YYYY-MM-DD 00:00:00")
                        this.filtro.date.search.max = moment(this.interval_date.end).format("YYYY-MM-DD 23:59:59");
                    }
                }


                window.sessionStorage.setItem('filter', JSON.stringify(this.filtro));


                this.requestEntriesFiltered(JSON.parse(JSON.stringify(this.filtro))).then(r => {
                    this.data = r;
                    this.loading = false;
                });
            }
        },
        computed: {
            ...mapGetters({
                entries: 'getEntries',
                domains: 'getDomains',
                programas: 'getOwnProgramas'
            }),
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }
</script>