<template>

    <div class="academica-el row-between">

        <template v-if="!edit">
            <div class="dato -middle" v-if="usertype == 'mentor'">
                <p><strong>Breve reseña profesional:</strong> {{user.profesional_resumen}}</p>
            </div>

            <div class="dato -middle">
                <p><strong>Experiencia profesional:</strong> {{domains.experience[user.profesional_xp]}}</p>
            </div>

            
            <div class="dato -middle">
                <p><strong>Empresa actual:</strong> {{user.profesional_current_company}}</p>
            </div>

            <div class="dato -middle">
                <p><strong>Cargo actual:</strong> {{user.profesional_current}}</p>
            </div>


            <div class="dato -middle">
                <p>
                    <strong>Linkedin:</strong> <a v-if="user.profesional_linkedin" :href="user.profesional_linkedin" target="_blank">Ver</a><template v-else>Sin definir</template>
                </p>
            </div>


        </template>

        <template v-if="edit">

            <ValidationObserver ref="form" class="row-between ais">

                <div class="contenedor-input" v-if="usertype == 'mentor'">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Breve reseña profesional*:</label>
                        <textarea :class="classes" v-model="data.profesional_resumen"></textarea>
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div class="contenedor-input -middle">
                    <ValidationProvider :rules="(usertype == 'mentor') ? 'required' : ''"  v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Empresa actual{{(usertype == 'mentor') ? '*' : ''}}</label>
                        <input type="text" :class="classes" v-model="data.profesional_current_company">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>


                <div class="contenedor-input -middle">
                    <ValidationProvider :rules="(usertype == 'mentor') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Cargo actual{{(usertype == 'mentor') ? '*' : ''}}</label>
                        <input type="text" :class="classes" v-model="data.profesional_current">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>



                <div class="contenedor-input -middle" v-if="domains.experience">
                    <ValidationProvider :rules="(usertype == 'mentor') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="empresa">Experiencia laboral{{(usertype == 'mentor') ? '*' : ''}}</label>
                        <select :class="classes" v-model="data.profesional_xp" name="empresa" id="empresa">
                            <option value="">Selecciona experiencia laboral</option>
                            <option :key="key" v-for="(n,key) in domains.experience" :value="key">{{n}}</option>
                        </select>
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                


                <div class="contenedor-input -middle">
                    <label for="">Linkedin</label>
                    <input type="text" v-model="data.profesional_linkedin">
                </div>

            </ValidationObserver>

            <div class="contenedor-botones">
                <div>
                    <button class="btn" @click="validate" :loading="loading">Guardar</button>
                    <button class="btn -skull-blue" @click="cancel">Cancelar</button>
                </div>
            </div>


        </template>

        <icon-editar v-if="!edit" @click="setEdit" :edit="edit"></icon-editar>


    </div>


</template>


<script>

    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'campo-profesionalmente',
        data: () => ({
            data: '',
            edit: false,
            loading: false
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                user: 'getuser',
                usertype : 'getUserType',
            })
        },
        methods: {
            ...mapActions(['requestChangeUser']),
            setEdit() {
                this.edit = true;
                this.data = JSON.parse(JSON.stringify(this.user));
                
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.requestChangeUser(this.data).then(r => {
                            if (r) {
                                this.loading = false;
                                this.edit = false;
                            }
                        });
                    }
                });
            },
            cancel() {
                this.data = JSON.parse(JSON.stringify(this.user));
                this.edit = false;
            }
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }
</script>