<template>

    <div class="contenedor-formulario -login row-center">


        <div class="contenedor-logo">
            <img src="/img/logo-uc3m-alumni.png" alt="Logo u3cm">
        </div>

        <div class="intro-text-login" v-if="!showlogin & !showregistro">
            <p>Bienvenido/a, a continuación podrás acceder a la plataforma si ya eres mentor/a o registrarte si aún no
                formas parte de la Red de mentores Alumni UC3M.
            </p>
        </div>
        <div class="intro-text-login" v-if="showlogin || showregistro">
            <p>¿Formas parte del colectivo UC3M: egresado/a, estudiante o mentor/a?</p>
        </div>

        <div class="login-box">

            <div class="saml-access xs" v-if="!showlogin & !showregistro">
                <h2>Ya soy mentor/a</h2>
                <a class="btn -skull-white" @click="showlogin = true">Inicia sesión</a>
            </div>

            <div class="saml-access xs" v-show="showlogin || showregistro">
                <a class="btn -skull-white"
                    href="https://gestionalumni.uc3m.es/saml2/cba24e10-2de7-44f7-be55-a712ba40d7bc/login">Soy colectivo
                    Uc3m</a>
            </div>






        </div>
        <div class="register-box">


            <div class="saml-access xs" v-if="!showlogin & !showregistro">
                <h2>Quiero ser mentor/a</h2>
                <a class="btn -skull-white" @click="showregistro = true">Regístrate</a>
            </div>

            <div class="saml-access xs" v-if="showlogin">
                <a v-if="!showformulario" class="btn -skull-white" @click="showformulario = true">No soy colectivo
                    Uc3m</a>
                <ValidationObserver ref="form" v-slot="{failed,valid}"  v-if="showformulario">
                    <form @submit.prevent="validate" :valid="valid" class="formulario">
                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                                placeholder="DNI">
                                <label for="">DNI, NIE o Pasaporte <popup-info :data="'ayuda-login'">
                                </popup-info></label>
                                <input type="text" :class="classes" v-model="data.username" name="dni">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="">Contraseña</label>
                                <input type="password" :class="classes" v-model="data.password" name="password"
                                    placeholder="Contraseña">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="things row-between">
                            <a @click="showRecovery = true">He olvidado mi contraseña</a>
                        </div>

                        <div class="contenedor-boton column-center">
                            <button class="btn -skull-white" :loading="loadinglogin">Iniciar sesión</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>

            <div class="saml-access xs" v-show="showregistro">
                <router-link :to="$t('links.registro')+'/mentor'" class="btn -skull-white">No soy colectivo Uc3m
                </router-link>
            </div>


        </div>

        <recovery-pass :type="'profesor'" v-if="showRecovery" @close="showRecovery = false"></recovery-pass>


    </div>

</template>

<script>

    import { mapActions } from 'vuex';

    import recoverypass from '@/components/recoverypass'

    import router from '@/router';

    export default {
        name: 'login-mentee',
        data: () => ({
            data: {
                username: '',
                password: '',
                type: 'profesor'
            },
            loadinglogin: false,
            dni: '',
            showRecovery: false,
            showlogin: false,
            showregistro: false,
            showformulario: false
        }),
        mounted(){
            if(this.$route.params.paso == 'registro'){
                this.showregistro = true;
            }

            if(this.$route.params.paso == 'login'){
                this.showlogin = true;
            }
        },
        methods: {
            ...mapActions([
                'login'
            ]),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loadinglogin = true;
                        this.login(this.data).then(r => {
                            this.loadinglogin = false;
                            if (r) {
                                if(window.sessionStorage.getItem('goto')){
                                    router.push(window.sessionStorage.getItem('goto'))
                                    window.sessionStorage.removeItem('goto');
                                }else{
                                    router.push({ name: 'home' })
                                }
                            }
                        })
                    }
                });
            }
        },
        components: {
            'recovery-pass': recoverypass
        }
    }
</script>