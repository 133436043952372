<template>
    <div class="wrapper -mi-perfil">
        <section class="encabezado -mi-perfil">
            <h1>Mi perfil</h1>
        </section>



        <section class="mi-perfil-intro" v-if="user">

            <div class="container row-start">


                <div class="imagen" :style="`background:url(${user.image_url}) center/cover no-repeat;`">
                    <a v-if="user.image_url" class="editar" @click="editimage = true">Editar</a>
                    <p @click="editimage = true" v-if="!user.image_url">Haz click para añadir imagen</p>
                </div>



                <div class="texto">

                    <h2>{{user.name}} {{user.surname}} {{user.surname_2}} <a :disabled="!user.profesional_linkedin" :href="user.profesional_linkedin" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a></h2>

                    <p v-if="user.profesional_current"><strong>Cargo actual:</strong> {{user.profesional_current}}</p>

                    <p v-if="user.profesional_current_company"><strong>Empresa actual:</strong> {{user.profesional_current_company}}</p>

                    <div class="nivel-completado">
                        <p>Nivel completado del perfil: <strong>{{percent}}%</strong></p>
                        <div class="bar" :complete="percent == 100">
                            <span :style="`width:${percent}%`" :active="!!user.image_url"></span>
                        </div>
                    </div>

                    <cambio-password v-if="user.saml != 1"></cambio-password>


                </div>

            </div>


        </section>

        <div class="perfil-body container" v-if="user">
            <informacion-personal :values="user"></informacion-personal>
            <informacion-academica></informacion-academica>
            <intereses :values="user"></intereses>
            <informacion-profesional></informacion-profesional>

            
            <p class="baja" v-if="usertype == 'mentee'">
                Si deseas mantenerte inactivo temporalmente o bien si deseas darte de baja definitivamente por favor rellena este <a href="/contacto">formulario</a>
            </p>

            <p class="baja" v-if="usertype == 'mentor'">
                Si deseas mantenerte inactivo temporalmente y no aparecer en los motores de búsqueda de mentores o bien si deseas darte de baja definitivamente por favor rellena este <a href="/contacto">formulario</a>
            </p>

        </div>

        <div class="loading" v-else></div>

        <imagen-editor @close="editimage = false" v-if="editimage"></imagen-editor>
    

    </div>

</template>


<script>

    import informacionacademica from '@/components/mi-perfil/informacion-academica'
    import informacionprofesional from '@/components/mi-perfil/informacion-profesional'
    import informacionpersonal from '@/components/mi-perfil/informacion-personal'
    import intereses from '@/components/mi-perfil/intereses'
    import cambiopassword from '@/components/mi-perfil/cambio-password'

    import imageeditor from '@/components/mi-perfil/imagen-editor';

    import { mapGetters } from 'vuex';

    export default {
        name: 'mi-perfil',
        data: () => ({
            data: '',
            editimage : false,
        }),
        computed: {
            ...mapGetters({
                user: 'getuser',
                domains: 'getDomains',
                server : 'getServer',
                complete : 'getComplete',
                usertype : 'getUserType'
            }),
            percent(){
                let total = 0;

                if(this.user.image_url){
                    total +=10;
                }

                if(this.complete.academics){
                    total +=20;
                }

                if(this.complete.profesional){
                    total +=20;
                }

                if(this.complete.mentoring){
                    total +=30;
                }

                if(this.complete.personal){
                    total +=20;
                }


                return total;
            }
        },
        components: {
            'informacion-academica': informacionacademica,
            'informacion-profesional': informacionprofesional,
            'informacion-personal': informacionpersonal,
            'intereses': intereses,
            'imagen-editor' : imageeditor,
            'cambio-password' : cambiopassword
        }

    }
</script>