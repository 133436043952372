<template lang="html">

    <div class="cover-popup row-center">

        <span class="close-cover" @click="close"></span>

        <div class="popup">

            <a @click="close" class="close">✕</a>

            <h2 class="title-pop">Editar imagen de perfil</h2>

            <div class="magic-zone">
                <dropzone v-if="!img" @emit="setDoc" ref="dropzone" :onlyimg="true"></dropzone>

                <img v-if="img" id="image" class="perfil" :src="img" alt="">
            </div>


            <a @click="eraseactual" v-if="!img" class="link">Eliminar imagen actual</a>

            <div class="contenedor-botones row-center" v-if="img">
                <div>
                    <button class="btn" @click="setImg" :loading="loading">Guardar</button>
                    <button class="btn -skull-blue" @click="close">Cancelar</button>
                </div>
            </div>
        </div>
    </div>


</template>

<script lang="js">



    import { mapActions } from 'vuex';

    import dropzone from '@/components/dropzone';

    import Cropper from 'cropperjs';

    import 'cropperjs/dist/cropper.css';



    export default {
        name: 'image-editor',
        props: ['avatarimg'],
        data() {
            return {
                img: '',
                imgb64: '',
                extension: '',
                nombre: '',
                loading: false,
                cropper : ''
            }
        },
        methods: {
            ...mapActions(['editImagenPerfil','requestEraseActualImg']),
            setDoc(value) {
                this.img = value.ficheroB64ParaAltas;

                setTimeout(() => {
                    const image = document.getElementById('image');
                    const cropper = new Cropper(image, {
                        aspectRatio: 1 / 1,
                    });
                    this.cropper = cropper;
                },200);

            },
            eraseImg() {
                this.img = '';
                this.imgb64 = '';
            },
            setImg() {

                this.loading = true;

                this.img = this.cropper.getCroppedCanvas().toDataURL();

                this.img = this.img.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, "");

                this.editImagenPerfil(this.img).then(() => {
                    this.$emit('setImg', this.img);
                    this.close();
                    this.loading = false;
                });

            },
            eraseactual(){
                this.requestEraseActualImg().then(() => {
                    this.close();
                    this.loading = false;
                });
            },
            close() {
                this.$emit('close');
            }
        },
        components: {
            'dropzone': dropzone,
        }
    }
</script>

<style scoped lang="scss">
    .cover-popup .popup .btn {
        margin-top: 0;
    }
</style>