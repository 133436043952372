<template>
    <div class="wrapper">
        <section class="encabezado -mis-mentorias">
            <h1>Mis mentorías</h1>
        </section>

        <section class="contenedor-mentorias container" v-if="mentorias">
            <div class="row between" v-if="mentorias.length != 0">
                <mentoria-el :data="mentoria" :key="key" v-for="(mentoria,key) in mentorias"></mentoria-el>
            </div>
            <template v-else>
                <p class="zero-results">Aún no tienes mentorías</p>
                <router-link v-if="usertype == 'mentee'" :to="$t('links.encuentramentor')" class="btn -dark-orange">Encuentra un mentor/a
                </router-link>
            </template>
        </section>

        <div class="loading" v-else></div>

    </div>
</template>


<script>

    import mentoria from '@/components/mentoria'

    import { mapGetters } from 'vuex';

    export default {
        name: 'mis-mentorias',
        components: {
            'mentoria-el': mentoria
        },
        computed: {
            ...mapGetters({
                mentorias: 'getMentorias',
                usertype : 'getUserType'
            })
        }
    }
</script>