export default {
    "formats": [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/msword',
        'image/*',
        '',
        'docx',
        'doc',
        'pptx',
        'pdf',
        'odt',
        'ppt',
        'odp',
        'csv',
        'xlsx',
        'png',
        'jpg',
        'gif',
        'tiff',
        'jpeg',
        "bmp",
        "tif",
        "odt",
        "txt",
        "pps",
        "ppsx",
    ],
    "relations": {
        'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'pdf': 'pdf',
        'msword': 'doc',
        'vnd.oasis.opendocument.text': 'odt',
        'vnd.ms-powerpoint': 'ppt',
        'vnd.oasis.opendocument.presentation': 'odp',
        'vnd.ms-excel': 'csv',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    },
    "images" :[
        'image/*',
        'png',
        'jpg',
        'gif',
        'tiff',
        'jpeg',
        "bmp",
        "tif",
        "svg+xml",
        "svg",
    ]
}