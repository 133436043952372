<template>



    <section class="perfil-module row-between -personal" :show="show">

        <h2 @click="setEdit">Intereses o preferencias de Mentorías</h2>

        <template v-if="show">

            <template v-if="!edit">

                <div class="dato">
                    <p><strong>Motivación para la participación:</strong> {{values.preferences_motivation}}
                    </p>
                </div>

                <div class="dato -middle">
                    <p v-if="values.area.length != 0">
                        <strong>Áreas profesionales:</strong>
                        <template v-for="(n,key) in values.area">
                            {{n.text}}<template v-if="key != values.area.length-1">;</template>
                        </template>
                    </p>
                    <p v-else>
                        <strong>Sector:</strong>
                        Sin definir
                    </p>
                </div>

                <div class="dato -middle">
                    <p v-if="values.sector.length != 0">
                        <strong>Sectores:</strong>
                        <template v-for="(n,key) in values.sector">
                            {{n.text}}<template v-if="key != values.sector.length-1">;</template>
                        </template>
                    </p>
                    <p v-else>
                        <strong>Sector:</strong>
                        Sin definir
                    </p>

                </div>

                <div class="dato -middle">
                    <p><strong>Modalidad de mentoría:</strong> {{domains.preferences[values.preferences_participation]}}
                    </p>
                </div>

                <div class="dato -middle">
                    <p v-if="values.language.length != 0">
                        <strong>Idiomas:</strong>
                        <template v-for="(n,key) in values.language">
                            {{n.text}}<template v-if="key != values.language.length-1">;</template>
                        </template>
                    </p>
                    <p v-else>
                        <strong>Idiomas:</strong>
                        Sin definir
                    </p>
                </div>

                <div class="dato -middle">
                    <p v-if="values.ability.length != 0">
                        <strong>Habilidades:</strong>
                        <template v-for="(n,key) in values.ability">
                            {{n.text}}<template v-if="key != values.ability.length-1">;</template>
                        </template>
                    </p>
                    <p v-else>
                        <strong>Habilidades:</strong>
                        Sin definir
                    </p>
                </div>

            
                <div class="dato">
                    <p><strong>Observaciones/ Otras habilidades:</strong> {{values.observations_other_abilities}}
                    </p>
                </div>

                <div class="dato -middle" v-if="usertype == 'mentor'">
                    <p>
                        <strong>Acepto formar parte de la Red de Mentores de este programa:</strong>
                        {{(values.visible_by_others == 1) ? 'Sí' : 'No'}}
                    </p>

                </div>

            </template>

            <template v-if="edit">

                <ValidationObserver ref="form" class="row-between">

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Por qué te interesa participar*</label>
                            <textarea :class="classes" v-model="data.preferences_motivation" name="" id="" cols="30"
                                rows="10"></textarea>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input -middle" v-if="domains.preferences">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="preferenciamentoria">Modalidad de mentoría*</label>
                            <select :class="classes" v-model="data.preferences_participation" name="preferenciamentoria"
                                id="preferenciamentoria">
                                <option value="">Selecciona preferencia</option>
                                <option :key="key" v-for="(n,key) in domains.preferences" :value="key">{{n}}
                                </option>
                            </select>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-multiselect -middle" v-if="domains.area">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="area">Áreas profesionales*</label>
                            <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                v-model="data.area" :options="domains.area" :searchable="true" :close-on-select="true"
                                :show-labels="false" placeholder="Selecciona el área">
                            </multiselect>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-multiselect -middle" v-if="sectores">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="area">Sectores*</label>
                            <multiselect :class="classes" :multiple="true" :track-by="'text'" :label="'text'"
                                v-model="data.sector" :options="sectores" :searchable="true" :close-on-select="true"
                                :show-labels="false" placeholder="Selecciona el sector">
                            </multiselect>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-multiselect -middle -dark" v-if="domains.language">
                        <label for="">Idiomas</label>
                        <multiselect :multiple="true" track-by="text" label="text" v-model="data.language"
                            :options="domains.language" :searchable="true" :close-on-select="true" :show-labels="false"
                            placeholder="Selecciona los idiomas">
                        </multiselect>
                    </div>


                    <div class="contenedor-multiselect -dark" v-if="domains.ability">
                        <label for="">Habilidades</label>
                        <multiselect :multiple="true" track-by="text" label="text" v-model="data.ability"
                            :options="domains.ability" :searchable="true" :close-on-select="true" :show-labels="false"
                            placeholder="Selecciona las habilidades">
                        </multiselect>
                    </div>

                    <div class="contenedor-input">
                        <ValidationProvider  v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Observaciones/ Otras habilidades</label>
                            <textarea :class="classes" v-model="data.observations_other_abilities" name="" id="" cols="30"
                                rows="10" maxlength="512"></textarea>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="toggle" v-if="usertype == 'mentor'">

                        <input type="checkbox" id="visible-otros" v-model="data.visible_by_others">
                        <label for="visible-otros">Acepto formar parte de la Red de Mentores de este programa. </label>
                        <popup-info :data="'visible-otros'"></popup-info>
                    </div>








                </ValidationObserver>

                <div class="contenedor-botones">
                    <div>
                        <button class="btn" @click="validate" :loading="loading">Guardar</button>
                        <button class="btn -skull-blue" @click="cancel">Cancelar</button>
                    </div>
                </div>

            </template>

        </template>

        <icon-editar @click="setEdit" :edit="edit" v-if="show && !edit"></icon-editar>

        <button class="show-btn" @click="show = !show">{{(show) ? '-' : '+'}}</button>


    </section>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'intereses',
        props: ['values'],
        mounted() {
            this.data = this.values;
            this.requestDomains();
            this.requestsector(this.data.area).then(r => {
                this.sectores = r;
            })
        },
        data: () => ({
            data: '',
            edit: false,
            loading: false,
            show: true,
            parsed: false,
            sectores: []
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                usertype: 'getUserType'
            })
        },
        methods: {
            ...mapActions(['requestChangeUser', 'requestDomains','requestsector']),
            setEdit() {
                this.edit = true;
                this.data = JSON.parse(JSON.stringify(this.values));
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.requestChangeUser(this.data).then(r => {
                            if (r) {
                                this.loading = false;
                                this.edit = false;
                            }
                        });
                    }
                });
            },
            cancel() {
                this.edit = false;
                this.data = JSON.parse(JSON.stringify(this.values));
            }
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        },
        watch: {
            'data.area'() {
                this.requestsector(this.data.area).then(r => {
                    this.sectores = r;
                })
            }
        }
    }
</script>