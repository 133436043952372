<template>
    <div class="wrapper -registro">

        <div class="top container row-between">

            <router-link to="/" class="logo-container">
                <img class="logo" src="/img/logo-uc3m-alumni.png" alt="Uc3m Alumni">
            </router-link>

            <div class="row-end">
                <p>¿Ya eres mentor o mentee?</p>
                <router-link :to="$t('links.login')" class="btn -skull-blue">Iniciar sesión</router-link>
            </div>

        </div>

        <div class="contenedor-formulario">

            <h1 v-if="data.type == 'profesor'">Nuevo Mentor/a</h1>
            <h1 v-if="data.type == 'mentee'">Nuevo Mentee</h1>

            <template v-if="paso == 1">

                <ValidationObserver ref="form" v-slot="{failed,valid}">

                    <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">

                        <legend>Datos Personales</legend>

                        <p class="tip">*Campos obligatorios</p>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="dni">DNI, NIE o pasaporte* <popup-info :data="'ayuda-login'">
                                    </popup-info></label>
                                <input type="text" :class="classes" v-model="data.dni" name="dni" id="dni">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle" v-if="data.type == 'mentee'">
                            <ValidationProvider rules="min:9" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="nia">NIA<popup-info :data="'ayuda-nia'">
                                    </popup-info></label>
                                <input type="text" :class="classes" v-model="data.nia" name="nia" id="nia">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="nombre">Nombre*</label>
                                <input type="text" :class="classes" v-model="data.name" name="nombre" id="nombre">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="apellidos">Primer apellido*</label>
                                <input type="text" :class="classes" v-model="data.surname" name="apellidos"
                                    id="apellidos">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="apellidos">Segundo apellido*</label>
                                <input type="text" :class="classes" v-model="data.surname_2" name="apellidos"
                                    id="apellidos">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="pais">País de residencia*</label>

                                <select :class="classes" v-model="data.country" name="pais" id="pais">
                                    <option value="">País de residencia</option>
                                    <option :value="pais" :key="key" v-for="(pais,key) in domains.countries">{{pais}}
                                    </option>
                                </select>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input -middle" v-show="data.country != 'España'">
                            <ValidationProvider :rules="(data.country != 'España') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="provincia">Provincia de residencia*</label>
                                <input type="text" :class="classes" v-model="data.province" name="provincia"
                                    id="provincia">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle" v-show="data.country == 'España'">
                            <ValidationProvider :rules="(data.country == 'España') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="provinciaselect">Provincia de residencia*</label>
                                <select :class="classes" v-model="data.province" name="provinciaselect"
                                    id="provinciaselect">
                                    <option value="">Provincia de residencia</option>
                                    <option :value="provincia" :key="key" v-for="(provincia,key) in domains.provinces">
                                        {{provincia}}</option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="email">Email personal*</label>
                                <input type="text" :class="classes" v-model="data.email" name="email" id="email">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="contenedor-input -middle">
                            <ValidationProvider :rules="'email'" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="email">Email UC3M</label>
                                <input type="text" :class="classes" v-model="data.email_internal" name="email"
                                    id="email">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="numeric|required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="phone">Teléfono*</label>
                                <input type="tel" v-model="data.phone" name="phone" id="phone" :class="classes">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>




                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="">Año de nacimiento*</label>
                                <year-datepicker v-model="data.birthday_date" :name="'start_date'" :class="classes">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="contenedor-radios">

                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                                class="row-center">
                                <h4>Género*:</h4>
                                <div class="radio">
                                    <input type="radio" name="gender" v-model="data.gender" id="gender-hombre"
                                        :value="0" :class="classes">
                                    <label for="gender-hombre">Masculino</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" name="gender" v-model="data.gender" id="gender-mujer" :value="1"
                                        :class="classes">
                                    <label for="gender-mujer">Femenino</label>
                                </div>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>


                        <div class="contenedor-input -middle" v-if="!data.cache_key">
                            <ValidationProvider
                                :rules="{required:true,regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }" vid="password">
                                <label for="email">Contraseña* <popup-info :data="'contrasena'">
                                    </popup-info></label>
                                <input type="password" :class="classes" v-model="data.password" name="password">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>


                            </ValidationProvider>
                        </div>
                        <div class="contenedor-input -middle" v-if="!data.cache_key">
                            <ValidationProvider
                                :rules="{required:true,confirmed:'password',regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="email">Repetir contraseña*</label>
                                <input type="password" :class="classes" v-model="data.password_confirmation"
                                    name="password">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </ValidationProvider>
                        </div>

                        <div class="contenedor-boton row-between">
                            <a>Paso 1 de 2</a>
                            <a class="btn -skull-white" @click="next">Siguiente</a>
                        </div>

                    </form>

                </ValidationObserver>


            </template>

            <template v-if="paso == 2">

                <ValidationObserver ref="form" v-slot="{failed,valid}">

                    <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">

                        <legend>Datos Profesionales</legend>

                        <div class="contenedor-input" v-show="false">
                            <label for="empresa">Perfil Linkedin</label>
                            <input type="text" v-model="data.profesional_linkedin" name="linkedin" id="linkedin">
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider :rules="'required'" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="empresa">Experiencia laboral* <popup-info :data="'experiencia-total'">
                                    </popup-info>
                                </label>
                                <select :class="classes" v-model="data.profesional_xp" name="empresa" id="empresa">
                                    <option value="">Selecciona experiencia laboral</option>
                                    <template v-if="domains.experience">
                                        <option :key="key" v-for="(n,key) in domains.experience" :value="key">{{n}}
                                        </option>
                                    </template>

                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(data.type == 'profesor') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="empresa">Empresa actual{{(data.type == 'profesor') ? '*' : ''}}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current_company"
                                    name="empresa" id="empresa">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(data.type == 'profesor') ? 'required' : ''"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="cargo">Cargo actual{{(data.type == 'profesor') ? '*' : ''}}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current" name="cargo"
                                    id="cargo">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input">
                            <label for="empresa">Perfil Linkedin</label>
                            <input type="text" v-model="data.profesional_linkedin" name="linkedin" id="linkedin">
                        </div>

                        <div class="contenedor-input" v-if="data.type == 'profesor'">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="resume">Breve reseña profesional*</label>
                                <textarea :class="classes" v-model="data.profesional_resumen" name="resume"
                                    id="resume"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        
                        <legend>Preferencias de mentoría</legend>
                        <div class="contenedor-input -middle">
                            <label for="preferenciamentoria">Modalidad de mentoría*</label>
                            <select required="true" v-model="data.preferences_participation" name="preferenciamentoria" id="preferenciamentoria">
                                <option :key="key" v-for="(n,key) in domains.preferences" :value="key">{{n}}
                                </option>
                            </select>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="domains.area">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="area">Áreas profesionales*</label>
                                <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                    v-model="data.area" :options="domains.area" :searchable="true"
                                    :close-on-select="true" :show-labels="false"
                                    placeholder="Seleccione una o varias áreas">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="sectores">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="area">Sectores profesionales*</label>
                                <multiselect :class="classes" :multiple="true" :track-by="'text'" :label="'text'"
                                    v-model="data.sector" :options="sectores" :searchable="true" :close-on-select="true"
                                    :show-labels="false" placeholder="Seleccione uno o varios sectores">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-multiselect -middle -white" v-if="domains.language">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="">Idiomas en mentorías*</label>
                                <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                    v-model="data.language" :options="domains.language" :searchable="true"
                                    :close-on-select="true" :show-labels="false"
                                    placeholder="Seleccione uno o varios idiomas">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="resume">Motivación para la participación*</label>
                                <textarea :class="classes" v-model="data.preferences_motivation" name="resume"
                                    id="resume"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="contenedor-check">
                            <ValidationProvider :rules="{ required: { allowFalse: false } }"
                                v-slot="{ errors,ariaInput,ariaMsg, classes }">
                                <input :class="classes" id="politica" name="politica" v-model="data.politica"
                                    type="checkbox" placeholder="politica" v-bind="ariaInput">
                                <label for="politica">Declaro haber entendido la información facilitada y consiento el
                                    tratamiento que se efectuará de mis datos de carácter personal con la finalidad de
                                    inscribirme en el programa.</label>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-check" v-if="data.type == 'profesor'">
                            <input id="visible" name="visible" v-model="data.visible_by_others" type="checkbox"
                                placeholder="visible">

                            <label for="visible" class="width-auto">Acepto formar parte de la Red de Mentores de este
                                programa.</label>
                            <popup-info class="white" :data="'visible-otros-registro'"> </popup-info>
                        </div>

                        <div class="texto-legal">
                            <h2>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS.</h2>
                            <p>RESPONSABLE DEL TRATAMIENTO. Universidad Carlos III de Madrid. Contacto DPO: dpd@uc3m.es
                            </p>
                            <p>IDENTIFICACIÓN DEL TRATAMIENTO, MENTORING ALUMNI UC3M</p>
                            <p>FINALIDADES. Gestionar su inscripción y participación como mentor/mente (cada uno en su
                                caso) en el programa Mentoring Alumni UC3M, así como participar en todas las actividades
                                que
                                puedan desarrollarse en el seno de la actividad del programa.</p>
                            <p>EJERCICIO DE DERECHOS. Podrá ejercer sus derechos de acceso, rectificación, supresión,
                                limitación, portabilidad y oposición a su tratamiento, mediante el envío de un correo a
                                la
                                siguiente dirección: dpd@uc3m.es</p>
                            <p>INFORMACIÓN ADICIONAL: Puede consultar la información adicional y detallada sobre nuestra
                                Política de Privacidad en <a href="https://www.uc3m.es/protecciondedatos"
                                    target="_blank">https://www.uc3m.es/protecciondedatos</a></p>
                        </div>
                        <div class="contenedor-boton row-between">
                            <a class="back" @click="paso--">Volver</a>
                            <a class="btn -skull-white" @click="validate" :loading="loading">Registrarme</a>

                        </div>
                    </form>

                </ValidationObserver>
            </template>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import router from '@/router';

    export default {
        name: 'registro',
        mounted() {
            this.requestDomains();

            this.data.dni = this.$route.query.dni;

            this.data.type = (this.$route.params.id == 'mentee') ? 'mentee' : 'profesor';

            if (this.$route.query.cache_key) {
                this.data.cache_key = this.$route.query.cache_key;
                this.requestUserData();
            }

            if (this.$route.query.paso == '2') {
                this.paso = 2;

                setTimeout(() => {
                    this.$refs.form.reset()
                }, 500)

            }

        },
        data: () => ({
            data: {
                'dni': '',
                'country': '',
                'province': '',
                'email': '',
                'email_internal': '',
                'phone': '',
                'password': '',
                'password_confirmation': '',
                'name': '',
                'surname': '',
                'surname_2': '',
                'gender': '',
                'title': '',
                'profesional_current': '',
                'profesional_current_company': '',
                'profesional_resumen': '',
                'profesional_xp': '',
                'town': '',
                'area': [],
                'sector': [],
                'preferences_participation': 0,
                'preferences_motivation': '',
                'type': '',
                'ability': [],
                'observations_other_abilities':null,
                'language': [],
                'visible_by_others': false,
                'username': '',
                'extra_program_id': null
            },
            paso: 1,
            loading: false,
            sectores: []


        }),
        methods: {
            ...mapActions([
                'requestRegister',
                'requestDomains',
                'uc3mUserData',
                'requestsector'
            ]),
            next() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.paso++;
                        this.$refs.form.reset()
                        console.log(this.$refs)                        
                    }
                });
            },
            cleanArrays(data) {
                let clean = ['ability', 'language', 'sector', 'area'];

                clean.forEach(e => {

                    let array = new Array();

                    data[e].forEach(v => {
                        array.push(v.id);
                    })

                    data[e] = array;

                });

                return data;

            },
            validate() {



                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        let data = JSON.parse(JSON.stringify(this.data));
                        data = this.cleanArrays(data);

                        if (data.cache_key) {
                            delete data.password;
                            delete data.password_confirmation;
                        }


                        data.birthday_date = Number(data.birthday_date.split("-")[0]);
                        this.data.birthday_date = new Date(this.data.birthday_date);


                        if (data.nia) {
                            data.nia = data.nia.split("0").pop();
                        }

                        this.requestRegister(data).then((r) => {
                            this.loading = false;
                            if (r) {
                                setTimeout(() => {
                                    router.push({ name: 'login' })
                                }, 3000);
                            }
                        })
                    }
                });
            },
            requestUserData() {
                this.uc3mUserData(this.data.cache_key).then(r => {
                    this.data.username = r.documento;
                    this.data.name = r.nomAlumno;
                    this.data.surname = r.ape1Alumno;
                    this.data.surname_2 = r.ape2Alumno;
                    this.data.email = r.correoElec;
                    this.data.email_internal = r.correoElec2;
                    this.data.dni = r.documento;
                })
            }
        },
        computed: {
            ...mapGetters({
                domains: 'getDomains',
            })
        },
        watch: {
            'data.area'() {
                this.requestsector(this.data.area).then(r => {
                    this.sectores = r;
                })
            }
        }
    }
</script>