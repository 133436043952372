<template>
    <div class="wrapper -login">

        <template v-if="!$route.params.id">


            <div class="contenedor-acceso">

                <div class="contenedor-logo">
                    <img src="/img/logo-uc3m-alumni.png" alt="">
                </div>

                <router-link :to="$t('links.login')+'/mentee'">
                    Acceso Mentees
                </router-link>
                <router-link :to="$t('links.login')+'/mentor'">
                    Acceso Mentores
                </router-link>
            </div>

        </template>

        <login-mentee v-if="$route.params.id == 'mentee'"></login-mentee>
        <login-mentor v-if="$route.params.id == 'mentor'"></login-mentor>

    </div>
</template>

<script>

    

    import loginmentee from '@/components/login-mentee';
    import loginmentor from '@/components/login-mentor';

    import router from '@/router'

    export default {
        name: 'login',
        mounted(){
            if(this.$route.query.notification_answer){
                router.push('/gracias')
            }
        },
        components : {
            'login-mentee' : loginmentee,
            'login-mentor' : loginmentor
        },

    }
</script>