<template>
    <div class="wrapper">
        <section class="encabezado -programas">
            <h1>Programas</h1>
        </section>

        <section class="intro-text">
            <h2>Consulta la oferta de programas mentoring UC3M en los que puedes participar como {{(usertype == 'mentee') ? 'mentee' : 'mentor/a'}}.</h2>
        </section>

        <section class="contenedor-programa container" v-if="!!programas">
            <programa-el :data="programa" :key="key" v-for="(programa,key) in programas"></programa-el>
        </section>


        <div v-else class="loading -small"></div>


    </div>
</template>


<script>

    import programa from '@/components/programa-el'

    import {mapGetters} from 'vuex';

    export default{
        name : 'programas',
        components : {
            'programa-el' : programa
        },
        computed : {
            ...mapGetters({
                programas : 'getProgramas',
                ownprogramas : 'getOwnProgramas',
                usertype : 'getUserType',
            })
        }
    }
</script>