<template>

    <article class="academica-el" :edit="edit">

        <template v-if="!edit && values">

            <a @click="setEdit">
                <icon-editar :edit="edit"></icon-editar>
            </a>
            <p><strong>Cargo:</strong> {{values.position}}</p>
            <p><strong>Empresa:</strong> {{values.company}}</p>
            <p><strong>Sector:</strong> {{values.sector}}</p>
            <!-- <p>{{values.start_date | parsedate}} -
                <template v-if="values.end_date">
                    {{values.end_date | parsedate}}
                </template>
                <template v-else>
                    Actualidad
                </template>
            </p> -->

        </template>

        <template v-if="edit">

            <icon-editar :edit="edit"></icon-editar>
            <icon-delete @click="erase" :loading="loadingerase" v-if="data.dataId" :edit="edit"></icon-delete>

            <ValidationObserver ref="form" class="row-between">
                <div class="contenedor-input">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Cargo*</label>
                        <input type="text" :class="classes" v-model="data.position">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div class="contenedor-input -middle">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Empresa*</label>
                        <input type="text" :class="classes" v-model="data.company">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>


                <div class="contenedor-input -middle">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="">Sector*</label>
                        <input type="text" :class="classes" v-model="data.sector">
                        <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <!-- <div class="contenedor-fechas">
                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Desde</label>
                            <own-datepicker v-model="data.start_date" :name="'start_date'" :class="classes"></own-datepicker>
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input">
                        <label for="" v-if="!data.actual">Hasta</label>
                        <div class="toggle">
                            <input type="checkbox" :id="'profesional-'+id" v-model="data.actual">
                            <label :for="'profesional-'+id">Actualmente</label>
                        </div>
                        <own-datepicker v-if="!data.actual" :disabled="data.actual" :minDate="data.start_date" v-model="data.end_date"></own-datepicker>
                    </div>

                    
                </div> -->

            </ValidationObserver>

            <div class="contenedor-botones">
                <div>
                    <button class="btn" @click="validate" :loading="loading">Guardar</button>
                    <button class="btn -skull-blue" @click="cancel">Cancelar</button>
                </div>

                
            </div>

        </template>



    </article>

</template>


<script>


    import { mapGetters, mapActions } from 'vuex';

    import moment from 'moment';

    export default {
        name: 'profesional',
        mounted() {
            if (this.new) {
                this.edit = true;
            }
        },
        data: () => ({
            edit: false,
            loading: false,
            loadingerase : false,
            data: {
                position: '',
                sector: '',
                company: '',
                actual: false
            }
        }),
        props: ['new', 'values','id'],
        computed: {
            ...mapGetters({
                domains: 'getDomains'
            })
        },
        methods: {
            ...mapActions([
                'saveProfesional',
                'removeProfesional'
            ]),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        
                        this.saveProfesional(this.data).then(r => {
                            if (r) {
                                this.edit = false;
                                this.loading = false;
                                this.$emit("reload")
                            }
                        });
                    }
                });
            },
            cancel() {
                this.edit = false;
                this.$emit('cancel')
            },
            setEdit() {
                this.edit = true;

                for(let i in this.values){
                    this.data[i] = this.values[i];
                }

                this.data.actual = (!this.values.end_date);
            },
            erase(){

                this.loadingerase = true;
                this.removeProfesional(this.data.dataId).then(r => {
                    if(r){
                        this.loadingerase = false;
                        this.$emit("reload");
                        this.edit = false;
                    }
                })
            }
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }


</script>